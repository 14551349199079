import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDocs, collection, query, where, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from './../Firebase'

// states (redux)
import { setUser  } from "./../redux/action";


export const useQuitAgency = () => {
    const { user } = useSelector(state=>state.appReducer);
    const dispatch = useDispatch()
    const [loadingState, setLoadingState] = useState(false)
    const [error, setError] = useState(null)
    const [succmesg, setSucmsg] = useState(null)
    
    const quitAgency =  async (agencyname, username) => {
        setLoadingState(true)
        setError(null)
            try {

                var time = new Date().getTime()

                // reference to the users collection
                const agenciesCollection = collection(db, "agencies");

                // query to find if a user exists with the given username
                const agencyQuery = query(agenciesCollection, where("name", "==", agencyname));

                // execute the query
                const querySnapshot = await getDocs(agencyQuery);

                // check if a document with the username exists
                if (!querySnapshot.empty) {
                    // if document exists, update the memebers array
                    const agencyDoc = querySnapshot.docs[0];
                    const agencyData = agencyDoc.data()
                    const agencyDocRef = doc(db, "usersdetails", agencyDoc.id);

                    const filtered = agencyData.members.filter(member => member !== username)
                    const updateAgency = { members: [ filtered ] }

                    // update the document with the new data
                    await updateDoc(agencyDocRef, updateAgency);
                    
                    // query user docs to update
                    const userquery = query(collection(db, "usersdetails"), where("username", "==", username))
                    // execute the query
                    const userquerySnapshot = await getDocs(userquery);

                    if (!userquerySnapshot.empty) {
                        // get the first matching document (assuming usernames are unique)
                        const userDoc = userquerySnapshot.docs[0];
                        const userDocRef = doc(db, "users", userDoc.id);

                        const updatedData = { useragency: '', agDate: 0 }
            
                        // Update the document with the new data
                        await updateDoc(userDocRef, updatedData);

                        // update states
                        var itemUpdate = { ...user, agency: '', agDate: 0 }
                        dispatch(setUser(itemUpdate))
                        setSucmsg(`agency "${agencyname}" has been quited.`)
                        setTimeout(() => { setSucmsg(null) }, 3000)
                        console.log(`agency "${agencyname}" has been quited.`);
                        console.log(`user with username "${username}" has been updated successfully.`);
                        setLoadingState(false)
                    } else {
                        setError(`no user found with username "${username}".`)
                        setTimeout(() => { setError(null) }, 3000)
                        console.log(`no user found with username "${username}".`);
                        setLoadingState(false)
                    }
                    
                } else {

                    // if agency exists, send a message that agency exist
                    setError(`agency with name "${agencyname}" does not exists. maybe it has been deleted from the db`)
                    setTimeout(() => { setError(null) }, 3000)
                    console.log(`agency with name "${agencyname}" does not exists.`);
                    setLoadingState(false)

                }

            } catch (err) {
                console.log('try catch error: ', err.message)
                setError('An error occured while processing your request. Please check your internet connection and try again')
                setTimeout(() => { setError(null) }, 3000)
                setLoadingState(false)
            }
        }


        return { quitAgency, loadingState, succmesg, error } 
}