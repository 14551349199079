






export const detailsContent = (name, membersCount, createdOn) => (`

    Agency Details: 

    Name: ${name}

    Members Count: ${membersCount}

    Agent Count: N/A
    
    Created: ${createdOn}

`)