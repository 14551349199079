import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDocs, collection, query, where, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from './../Firebase'

// states (redux)
import { setUser  } from "./../redux/action";


export const useCreateAgency = () => {
    const { user } = useSelector(state=>state.appReducer);
    const dispatch = useDispatch()
    const [loadingState, setLoadingState] = useState(false)
    const [error, setError] = useState(null)
    const [succmesg, setSucmsg] = useState(null)
    const [tmpcreateagency, settmpcreateagency] = useState(null)

        const createAgency =  async (agencyname, userid, username) => {
            setLoadingState(true)
            setError(null)
            try {

                var time = new Date().getTime()

                // reference to the users collection
                const agenciesCollection = collection(db, "agencies");

                // query to find if a user exists with the given username
                const agencyQuery = query(agenciesCollection, where("name", "==", agencyname));

                // execute the query
                const querySnapshot = await getDocs(agencyQuery);

                // check if a document with the username exists
                if (querySnapshot.empty) {
                    // if no document exists, create a new one
                    const newAgency = {
                        name: agencyname,
                        createdBy: userid,
                        creatorName: username,
                        members: [username], 
                        date: time, 
                        joinable: false,
                        createdAt: new Date(), // timestamp for when the agency was created
                    };

                    const docRef = await addDoc(agenciesCollection, newAgency);
                    const createdAgency = { agencyid: docRef.id, ...newAgency }

                    // query user docs to update
                    const userquery = query(collection(db, "usersdetails"), where("username", "==", username))
                    // execute the query
                    const userquerySnapshot = await getDocs(userquery);

                    if (!userquerySnapshot.empty) {
                        // get the first matching document (assuming usernames are unique)
                        const userDoc = userquerySnapshot.docs[0];
                        const userDocRef = doc(db, "usersdetails", userDoc.id);

                        const updatedData = { agency: agencyname, agDate: time }
            
                        // Update the document with the new data
                        await updateDoc(userDocRef, updatedData);

                        // update states
                        var itemUpdate = { ...user, agency: agencyname, agDate: time }
                        dispatch(setUser(itemUpdate))
                        settmpcreateagency(createdAgency)
                        setSucmsg(`agency "${agencyname}" has been created.`)
                        setTimeout(() => { setSucmsg(null) }, 3000)
                        console.log(`agency "${agencyname}" has been created.`);
                        console.log(`user with username "${username}" has been updated successfully.`);
                        setLoadingState(false)
                    } else {
                        setError(`no user found with username "${username}".`)
                        setTimeout(() => { setError(null) }, 3000)
                        console.log(`no user found with username "${username}".`);
                        setLoadingState(false)
                    }
                    
                } else {

                    // if agency exists, send a message that agency exist
                    setError(`agency with name "${agencyname}" already exists. use /join agency ${agencyname} to join it`)
                    setTimeout(() => { setError(null) }, 3000)
                    console.log(`agency with name "${agencyname}" already exists.`);
                    setLoadingState(false)
                }
                
            } catch (err) {
                console.log('try catch error: ', err.message)
                setError('An error occured while processing your request. Please check your internet connection and try again')
                setTimeout(() => { setError(null) }, 3000)
                setLoadingState(false)
            }
        }


        return { createAgency, loadingState, succmesg, error, tmpcreateagency } 
}