import { useState } from "react";
import { getDocs, collection, query, where, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from './../Firebase'



export const useMuteUser = () => {
    const [loadingState, setLoadingState] = useState(false)
    const [error, setError] = useState(null)
    const [muteSucc, setmutesucc] = useState(null)

        const muteUser =  async (username, whatMute) => {
            setLoadingState(true)
            setError(null)
            // console.log(username, whatMute)
            try {

                // query user docs to update
                const userquery = query(collection(db, "usersdetails"), where("username", "==", username))
                // execute the query
                const userquerySnapshot = await getDocs(userquery);

                if (!userquerySnapshot.empty) {
                    // get the first matching document (assuming usernames are unique)
                    const userDoc = userquerySnapshot.docs[0];
                    const userDocRef = doc(db, "usersdetails", userDoc.id);

                    const updatedData = { isMute: whatMute }
        
                    // Update the document with the new data
                    await updateDoc(userDocRef, updatedData);

                    // update states
                    setmutesucc(`user with username "${username}" has been ${whatMute === true ? 'muted' : 'unmuted'}.`)
                    setTimeout(() => { setmutesucc(null) }, 3000)
                    console.log(`user "${username}" has been ${whatMute === true ? 'muted' : 'unmuted'}.`);
                    setLoadingState(false)
                } else {
                    setError(`no user found with username "${username}".`)
                    setTimeout(() => { setError(null) }, 3000)
                    console.log(`no user found with username "${username}".`);
                    setLoadingState(false)
                }

            } catch (err) {
                console.log('try catch error: ', err.message)
                setError('an error occured while trying to mute user. please check your internet connection and try again or refresh the page')
                setTimeout(() => { setError(null) }, 3000)
                setLoadingState(false)
            }
        }


        return { muteUser, loadingState, error, muteSucc } 
}