import {useState} from "react";

const PRICEBOT_API_URL = 'https://superlamina.onrender.com'

const pricebotService = () => {
  return {
    summon: async () => {
      return fetch(`${PRICEBOT_API_URL}/api/price-bot`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
      }).then((response) => response.json());
    },
    sendMessage: async (message, messages) => {
      return fetch(`${PRICEBOT_API_URL}/api/price-bot`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          message,
          messages
        })
      }).then((response) => response.json()).catch(() => {
        console.log('Error occurred')
      });
    }
  }
}

export const usePricebot = () => {
  /*
    Messages are history of AI & User interaction data. Eventually it will be replaced by sockets.
   */
  const [messages, setMessages] = useState();
  const [summoned, setSummoned] = useState(false);

  const service = pricebotService()

  const saveMessages = (response) => {
    setMessages(response);
    return response;
  }

  return {
    summoned: summoned,
    summon: async () => await service.summon().then(saveMessages).then(response => {
      setSummoned(true)
      return response;
    }),
    sendMessage: async (message) => await service.sendMessage(message, messages).then(saveMessages)
  }
}