import { useState } from "react";
import { getDocs, collection, query, where, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from './../Firebase'


export const useListAgencies = () => {
    const [error, setError] = useState(null)
    const [loadingState, setLoadingState] = useState(false)
    const [thelist, setTheList] = useState(null)

    const listAgencies = async () => {
        try {    

            // get a reference to the "agencies" collection
            const agenciesCollection = collection(db, "agencies");
            // fetch all documents in the collection
            const querySnapshot = await getDocs(agenciesCollection);
            // map the documents into an array of agency data
            const agenciesList = querySnapshot.docs.map((doc) => ({
                id: doc.id, // include the document ID
                ...doc.data(), // spread the document data
            }));

            // Update the state with the fetched agencies
            setTheList(agenciesList)
            setLoadingState(false)
            
        } catch (err) {
            console.log('try catch error: ', err.message)
            setError('error fetching agencies. please check your internet connection and try again')
            setTimeout(() => { setError(null) }, 3000)
            setLoadingState(false)
        }
    }


    return { listAgencies, loadingState, error, thelist } 
}