import { useState } from "react";
import { useDispatch } from "react-redux";
import { initializeApp } from "firebase/app";
import { 
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
} from "firebase/auth";
import { getDocs, collection, query, where, addDoc } from "firebase/firestore";
import { db } from './../Firebase'

import { setUser } from "../redux/action";


const firebaseConfig = {
    apiKey: "AIzaSyANYwkXmh2SqzPf2cZUF0Ne3L8CjHG1E-o",
    authDomain: "quasarid.firebaseapp.com",
    projectId: "quasarid",
    storageBucket: "quasarid.appspot.com",
    messagingSenderId: "468240254391",
    appId: "1:468240254391:web:77985c5734e62017d21432",
    measurementId: "G-WV222WYTT1"
};

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const googleprovider = new GoogleAuthProvider()

export const useAuth = () => {
    const dispatch = useDispatch()
    const [error, setError] = useState(null)
    const [loadingState, setLoadingState] = useState(false)
    const [succmesg, setSucmsg] = useState(null)

    const signInWithGoogle = async (username) => {
        try {
            setLoadingState(true)
            const response = await signInWithPopup(auth, googleprovider)
            const user = response.user
            console.log(user.email, username)


            // reference to the users collection
            const usersCollection = collection(db, "usersdetails");

            // query to find if a user exists with the given username
            const userQuery = query(usersCollection, where("username", "==", username));

            // execute the query
            const querySnapshot = await getDocs(userQuery);

            // check if a document with the username exists
            if (querySnapshot.empty) {
                // if no document exists, create a new one
                const newUser = {
                    username,
                    useremail: user.email,
                    agency: '',
                    agDate: 0, 
                    isAdmin: false, 
                    isMute: false,
                    createdAt: new Date(), // timestamp for when the user is created
                };

                const docRef = await addDoc(usersCollection, newUser);
                const createdUser = { userid: docRef.id, ...newUser }

                // update states
                dispatch(setUser(createdUser))
                setSucmsg(`user with username "${username}" has been created.`)
                setTimeout(() => { setSucmsg(null) }, 3000)
                console.log(`user with username "${username}" has been created.`);
                localStorage.setItem('superluminaluser', JSON.stringify({ username }))
                setLoadingState(false)
                
            } else {

                // if user exists, get the first matching document
                const existingUserDoc = querySnapshot.docs[0];
                const existingUser = { userid: existingUserDoc.id, ...existingUserDoc.data() };
                
                // update states
                dispatch(setUser(existingUser))
                setSucmsg(`user with username "${username}" has been logged in.`)
                setTimeout(() => { setSucmsg(null) }, 3000)
                console.log(`user with username "${username}" has been logged in.`);
                localStorage.setItem('superluminaluser', JSON.stringify({ username }))
                setLoadingState(false)
            }
    
        } catch (err) {
            console.log('try catch error: ', err.message)
            setError('an error occured while processing your request. Please check your internet connection and try again')
            setTimeout(() => { setError(null) }, 3000)
            setLoadingState(false)
        }
    }


    return { signInWithGoogle, loadingState, error, succmesg } 
}