import { useState } from "react";
import { useDispatch } from "react-redux";
import { getDocs, collection, query, where, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from './../Firebase'

export const useGetAgency = () => {
    const [error, setError] = useState(null)
    const [loadingState, setLoadingState] = useState(false)
    const [tmpagency, setTmpagency] = useState(null)
    const dispatch = useDispatch()

    const getAgency = async (agencyname) => {
        try {

            // reference to the agencies collection
            const agenciesCollection = collection(db, "agencies");

            // query to find if a user exists with the given username
            const agencyQuery = query(agenciesCollection, where("name", "==", agencyname));

            // execute the query
            const querySnapshot = await getDocs(agencyQuery);

            // check if a document with the username exists
            if (!querySnapshot.empty) {
                // if document exists, update the memebers array
                const agencyDoc = querySnapshot.docs[0];
                const agencyData = agencyDoc.data()

                setTmpagency(agencyData)
                setLoadingState(false)
                
            } else {

                // if agency exists, send a message that agency exist
                setError(`agency with name "${agencyname}" does not exists. use /create agency ${agencyname} to create it`)
                setTimeout(() => { setError(null) }, 3000)
                console.log(`agency with name "${agencyname}" does not exists.`);
                setLoadingState(false)
            }
   
        } catch (err) {
            console.log('try catch error: ', err.message)
            setError('an error occured while processing your request. Please check your internet connection and try again')
            setTimeout(() => { setError(null) }, 3000)
            setLoadingState(false)
        }
    }


    return { getAgency, loadingState, error, tmpagency } 
}