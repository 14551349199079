


export const helpContent = `

    Account Management:

    /create user <name> - Create a new account

    /login <name> - Login to your account

    /logout - Logout from your account


    Agency Management:

    /create agency <name> - Create a new agency (an agency isn't joinable by default; use the /joinable command to allow it)
    
    /joinable on/off - add join restriction to your agency

    /list - List all agencies

    /agency details - Show your agency details

    /join agency <name> - Join an agency

    /info agency <name> - Show an agency details

    /quit agency - Leave your current agency

    /motd - See message of the day (MOTD)
    
    /summon pricebot - Assist you with cryptocurrency-related inquiries

    
    Help:
    
    /help - Show this help message

`