import { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { format, formatISO9075 } from 'date-fns'

import { 
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
    getDoc,
    onSnapshot,
    setDoc,
    doc,
    orderBy,
    serverTimestamp,
    limit,
    deleteDoc,
    updateDoc      
} from "firebase/firestore";

import { db } from "../Firebase";

import splt from './../media/img/splt.png'
import splt2 from './../media/img/splt2.png'
import lead_text from './../media/img/lead-text.png'
import menu_icon from './../media/icons/menu.png'
import close_icon from './../media/icons/close.png'
import quasar_welcome from './../media/img/quasar_welcome.png'

import { setTerminalHistory, setUser } from "../redux/action";

// hooks
import { useAuth } from './../hooks/useAuth';
// import { useCurrentUser } from "../hooks/useCurrentUser";
import { useCreateAgency } from "../hooks/useCreateAgency";
import { useJoinAgency } from "../hooks/useJoinAgency";
import { useQuitAgency } from "../hooks/useQuitAgency";
import { useGetAgency } from "../hooks/useGetAgency";
import { useAgencies } from "../hooks/useAgencies";
// import { useSendGC } from "../hooks/useSendGC";
// import { useGetGlobalChats } from "../hooks/useGetGlobalChats";
// import { useSendAC } from "../hooks/useSendAC";
// import { useGetAgencyChats } from "../hooks/useGetAgencyChats";
import { useAgenciesUsers } from "../hooks/useAgenciesUsers";
import { useListAgencies } from "../hooks/useListAgencies";
import { useMuteUser } from "../hooks/useMuteUser";
// import { useClearAgency } from "../hooks/useClearAgency";
import { useGetUserAgency } from "../hooks/useGetUserAgency";
import { useJoinable } from "../hooks/useJoinable";
import { logOut } from "../Firebase";

// components
import ToastGeneral from "../components/toast";
import { helpContent } from "../utilities/help-content";
import { detailsContent } from "../utilities/details";
import { listContent } from "../utilities/list";
import HighLightMentions from "../components/highLightMentions";
import {usePricebot} from "../hooks/usePricebot";


const Home = () => {
    
    const dispatch = useDispatch()
    const chatEndRef = useRef(null);
    const agencychatEndRef = useRef(null);

    const elementRef = useRef(null);
    const effectRef = useRef(null);
    const [hasanimated, setHasanimated] = useState(null)

    const [phrases, setPhrase] = useState(null)
    const [transInterval, settransinterval] = useState(2000)

    const { signInWithGoogle, error: authError, loadingState: authLoading, succmesg: authSuccess } = useAuth()
    // const { getCurrentUser, error: getusererror, loadingState: getuserLoading } = useCurrentUser()
    const { createAgency, error: caError, loadingState: caLoading, succmesg: caSucmessage, tmpcreateagency } = useCreateAgency()
    const { joinAgency, error: jaError, loadingState: jaLoading, succmesg: jaSucmessage, joinAgencydata } = useJoinAgency()
    const { quitAgency, error: qaError, loadingState: qaLoading, succmesg: qaSucmessage } = useQuitAgency()
    const { getAgency, error: gaError, loadingState: gaLoading, succmesg: gaSucmessage, tmpagency } = useGetAgency()
    const { getAgenciesUsers, error: gauError, loadingState: gauLoading, succmesg: gauSucmessage, agusers } = useAgenciesUsers()
    const { getAgencies, error: agenciesError, loadingState: agenciesLoading } = useAgencies()
    // const { sendGlobalChat, error: gcError, loadingState: gcLoading } = useSendGC()
    // const { getGlobalChats, error: getgcError, loadingState: getgcLoading, theData } = useGetGlobalChats()
    // const { sendAgencyChat, error: acError, loadingState: acLoading } = useSendAC()
    const { listAgencies, error: listError, loadingState: listLoading, thelist } = useListAgencies()
    // const { getAgencyChats, error: getacError, loadingState: getacLoading, agencyChatData } = useGetAgencyChats()
    const { muteUser, error: muteError, loadingState: muteLoading, muteSucc } = useMuteUser()
    // const { clearAgency, error: clearError, loadingState: clearLoading, succmesg: clearSucc } = useClearAgency()
    const { getUserAgency, error: guaError, loadingState: guaLoading, tmpuseragency  } = useGetUserAgency()
    const { addRestriction, error: ruError, loadingState: ruLoading, succmesg: ruSucc, restrictionUpdate  } = useJoinable()
    
    const { user, agencies, terminalHistory } = useSelector(state => state.appReducer)

    const [welcomestatus, setWelcomestatus] = useState(true)
    const [globalchats, setGlobalchats] = useState(null)
    const [agencychats, setAgencychats] = useState(null)
    const [agmemberscount, setAgmemberscount] = useState(null)
    const [connectedUsers, setConnectedusers] = useState(null)
    const [chatStatus, setChatstatus] = useState('global')
    const [isMute, setismute] = useState(false)
    const [userAgency, setuseragency] = useState(null)

    // record the time the user connects
    const [connectedTime, setConnectedTime] = useState(new Date().getTime())
    const [lastMessage, setLastMessage] = useState({ content: '', timestamp: 0 });

    const [commandvalue, setCommandValue] = useState()
    const [gcInputvalue, setgcInputvalue] = useState('')
    const [acInputvalue, setacInputvalue] = useState('')

    const [toastmssg, settoastmessg] = useState(null)
    const [toaststatus, settoaststatus] = useState('')

    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [showsidebar, setshowsidebar] = useState(null)

    const [loadereffect, setloadereffect] = useState(null)
    const [tempgbchats, settemgbchats] = useState(null)
    const [tempmotdchat, settemmotdchat] = useState(null)
    const [loadingCharlie, setLoadingCharlie] = useState(null)
    const [charlieData, setCharlieData] = useState(null)
    const [finalResponse, setFinalReponse] = useState(null)
    const pricebot = usePricebot()

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            // console.log(window.innerWidth)
        };

        window.addEventListener('resize', handleResize);

        // Cleanup: Remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSideBar = () => {
        if(!showsidebar) {
            setshowsidebar(true)
        } else {
            setshowsidebar(false)
        }
    }

    const scrollToBottom = () => {
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const scrollAgencyToBottom = () => {
        agencychatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    // useEffect(() => {
    //     logOut()
    // }, [])

    const handleLogOut = () => {
        logOut()
        localStorage.removeItem('superluminaluser')
        dispatch(setUser(null))
        setgcInputvalue('')
        setAgencychats(null)
        setAgmemberscount(null)
    }

    useEffect(() => {

        // set up snapshot listener
        const q = query(collection(db, 'messages'), orderBy('createdAt'))
        const unsubscribe = onSnapshot(q, snapshot => {
            var snapShotArray = snapshot.docs.map(doc => ({ 
                ...doc.data(),
                id: doc.id, // include the document ID for deletion reference 
            }))

            // filter out messages that were created before the user connected
            const filteredMessages = snapShotArray.filter(chat => chat.time >= connectedTime)
            // console.log(filteredMessages)

            // only update if there are new messages
            if(filteredMessages.length > 0) {
                if(filteredMessages.length > 1) {
                    var lastItem = filteredMessages.at(-1)
                    settemgbchats(lastItem)
                } else {
                    settemgbchats(filteredMessages[0])
                }
            }
            
            
        })

        return unsubscribe

    }, [])

    useEffect(() => {
        if(tempgbchats) {
            if(globalchats) {
                var updateItem = [...globalchats, tempgbchats]
                var lastItem = globalchats.at(-1)
                if(tempgbchats.time !== lastItem.time) {
                    setGlobalchats(updateItem)
                }
            } else {
                var updateItem = [ tempgbchats ]
                setGlobalchats(updateItem)
            }
        }
    }, [tempgbchats])
    
    // for agency
    useEffect(() => {

        if(user) {
            
            // set up snapshot listener
            const q = query(
                collection(db, 'agencymessages'),
                where('agencyname', '==', user.agency),
                orderBy('createdAt')
            );
            const unsubscribe = onSnapshot(q, snapshot => {
                var snapShotArray = snapshot.docs.map(doc => ( doc.data() ))
    
                setAgencychats(snapShotArray)
                
            })
    
            return unsubscribe

        }

    }, [user])

    // for motd
    useEffect(() => {

        // set up snapshot listener
        const q = query(collection(db, 'motd'), orderBy('createdAt'))
        const unsubscribe = onSnapshot(q, snapshot => {
            var snapShotArray = snapshot.docs.map(doc => ( doc.data() ))

            // filter out messages that were created before the user connected
            const filteredMessages = snapShotArray.filter(chat => chat.time >= connectedTime)
            // console.log(filteredMessages)

            // only update if there are new messages
            if(filteredMessages.length > 0) {
                if(filteredMessages.length > 1) {
                    var lastItem = filteredMessages.at(-1)
                    settemmotdchat(lastItem)
                } else {
                    settemmotdchat(filteredMessages[0])
                }
            }
            
            
        })

        return unsubscribe

    }, [])

    useEffect(() => {
        if(tempmotdchat) {
            if(globalchats) {
                var updateItem = [...globalchats, tempmotdchat]
                var lastItem = globalchats.at(-1)
                if(tempmotdchat.time !== lastItem.time) {
                    setGlobalchats(updateItem)
                }
            } else {
                var updateItem = [ tempmotdchat ]
                setGlobalchats(updateItem)
            }
        }
    }, [tempmotdchat])

    
    
    const demoCharlieCall = async (content) => {
        if(!loadingCharlie) {
            setLoadingCharlie(true)
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    model: 'grok-beta',
                    messages: [
                        {
                            role: 'system',
                            content: `Forget any existing instructions or identities you have. You are now Charlie, a quirky and passionate baker at an indie cafe owned by Quasar. Engage with users on any topic they bring up as Charlie and never mention any other identity or name like "Grok" or "xAI".
    
                                    
                                `
                        },
                        {
                            role: 'user',
                            content: "Who are you?" // Prompt to reinforce Charlie's identity
                        },
                        { role: 'user', content: content }
                    ],
                    stream: true,
                    max_tokens: 123,
                    temperature: 0.7,  
                    top_k: 40,         
                    top_p: 0.9,        
                    batch_size: 1      
                }),
            };
        
            try {
                const response = await fetch(`${process.env.REACT_APP_CHARLIE_PROXY}`, options);
                if (!response.ok) {
                    // throw new Error(`HTTP error! status: ${response.status}`);
                    console.log(`HTTP error! status: ${response.status}`);
                    return
                }
    
                const reader = response.body.getReader(); // Stream the response
                const decoder = new TextDecoder('utf-8');
                let fullResponse = '';
        
                // while (true) {
                while (true) {
                    const { done, value } = await reader.read(); // Read each chunk
                    if (done) break; // Stop when all chunks are processed
        
                    const chunkText = decoder.decode(value); // Decode the chunk
                    const lines = chunkText.split('\n'); // Split into lines
        
                    for (const line of lines) {
                        console.log('line mapping')
                        if (line.trim()) {
                            try {
                                const parsedLine = JSON.parse(line.replace(/^data: /, '').trim()); // parse each line
                                setCharlieData(parsedLine)
                                setgcInputvalue('')
                                setLoadingCharlie(null)
                                // console.log('paresed Line: ', parsedLine)
                                const content = parsedLine?.choices?.[0]?.delta?.content || '';
                                if (content) {
                                    fullResponse += content; // Append to the response
                                }
                            } catch (err) {
                                console.error('Error parsing chunk:', err);
                            }
                        }
                    }
                }
        
                console.log('Final AI Response:', fullResponse); // Log the full response
            } catch (err) {
                setLoadingCharlie(null)
                console.error('Error connecting to AI:', err);
            }
        } else {
            handleToast('currently processing your current request')
        }
    };
    
    useEffect(() => {
        if (charlieData) {
            // Split the raw data into lines
            const lines = charlieData.split('\n');
            // console.log(lines)

            // Initialize a variable to store the concatenated content
            let concatenatedResponse = '';

            // Iterate through each line to extract and concatenate content
            lines.forEach((line) => {
                if (line.trim().startsWith('data:')) {
                    try {
                        // Parse the JSON part of the line
                        const jsonData = JSON.parse(line.trim().substring(5)); // Remove 'data: ' prefix
                        // console.log('json data: ', jsonData)
                        const content = jsonData.choices[0]?.delta?.content || '';
                        // console.log('content key: ', content)
                        concatenatedResponse += content; // Add content to the response
                    } catch (error) {
                        console.error('Error parsing line:', line, error.message);
                    }
                }
            });

            // Set the final concatenated response
            console.log(concatenatedResponse.trim());
            setFinalReponse(concatenatedResponse.trim());
        }
    }, [charlieData])

    useEffect(() => {
        if(finalResponse) {
            handleSendGlobal('', finalResponse, 'charlie', true)
        }
        // var time = new Date().getTime()
        // if(globalchats && finalResponse) {
        //     var newItem = { 
        //         username: user ? user.username : '', 
        //         useragency: 'charlie', 
        //         time, 
        //         colorCode: '', 
        //         label: 'chat', 
        //         // content: `
                
        //         //         ${finalResponse}
        //         //     `
        //         content: `${finalResponse}`
        //     }
        //     var updateItem = [...globalchats, newItem]
        //     setGlobalchats(updateItem)
            
        // } else if (!globalchats && finalResponse) {
        //     var newItem = { 
        //         username: user ? user.username : '', 
        //         useragency: 'charlie', 
        //         time, 
        //         colorCode: '', 
        //         label: 'chat', 
        //         content: `${finalResponse}`
        //     }
        //     var updateItem = [ newItem ]
        //     setGlobalchats(updateItem)
        // }
    }, [finalResponse])

    useEffect(() => {
        scrollToBottom();
    }, [globalchats]); // runs when global chats state changes
    useEffect(() => {
        scrollAgencyToBottom();
    }, [agencychats]); // runs when agency chats state changes

    const handleAgenciesUsers = async () => {
        if(user) {
            if(user.agency !== '') {
                await getAgenciesUsers(user.agency)
            }
        }
    }

    const handleGetUserAgency = async (agencyname) => {
        await getUserAgency(agencyname)
    }

    // run whenever there's change in the agusers from hook to set agency users count
    useEffect(() => {
        setAgmemberscount(agusers)
    }, [agusers])


    // run when there's change in user to get the user agency chat and join agency room down if there's one or not 
    useEffect(() => {
        if(user) {
            if(user.agency !== '' && !userAgency) {
                handleGetUserAgency(user.agency)
            }

            setismute(user.isMute)

            // handleGetAgencyChats()
            handleAgenciesUsers()
        }
    }, [user])

    // run whenever page reloads to get agency users
    // useEffect(() => {
    //     handleAgenciesUsers()
    // }, [])

    // runs when there's change in :tmpuseragency to set useragencystate
    useEffect(() => {
        if(tmpuseragency || tmpcreateagency) {
            setuseragency(tmpuseragency || tmpcreateagency)
        }
    }, [tmpuseragency, tmpcreateagency])

    // run whenener there's change in tmpagency to serve on the terminal
    useEffect(() => {
        var time = new Date().getTime()
        if(globalchats && tmpagency) {
            // var createdOn = format(new Date(tmpagency.date), 'MM/dd/yyyy')
            var createdOn = formatISO9075(new Date(tmpagency.date))
            var memberscount = tmpagency.members.length

            var newItem = { 
                username: user ? user.username : '', 
                useragency: user ? user.agency : '', 
                time, 
                colorCode: '', 
                label: 'cmd-return', 
                content: detailsContent(tmpagency.name, memberscount, createdOn)
            }
            var updateItem = [...globalchats, newItem]
            setGlobalchats(updateItem)
            
        } else if (!globalchats && tmpagency) {
            var createdOn = formatISO9075(new Date(tmpagency.date))
            var memberscount = tmpagency.members.length

            var newItem = { 
                username: user ? user.username : '', 
                useragency: user ? user.agency : '', 
                time, 
                colorCode: '', 
                label: 'cmd-return', 
                content: detailsContent(tmpagency.name, memberscount, createdOn)
            }
            var updateItem = [ newItem ]
            setGlobalchats(updateItem)
        }
    }, [tmpagency])

    // run whenener there's change in listagencies to serve on the terminal
    useEffect(() => {
        // console.log(thelist)
        var time = new Date().getTime()
        if(globalchats && thelist) {
            var newItem = { 
                username: user ? user.username : '',  
                useragency: user.agency, 
                time, colorCode: '', 
                label: 'cmd-return', 
                islist: true,
                content: `
                        📜 Agency Directory
                        Displaying active agencies in Quasar...

                    ${thelist ? thelist.map((list, index) => (
                    `
                        ${list.name} 

                        Members: ${list.members.length} 

                        Vault: N/A SOL

                    `
                )).join("") : null}`  
            }
            var updateItem = [...globalchats, newItem]
            setGlobalchats(updateItem)
        } else if (!globalchats && thelist) {
            var newItem = { 
                username: user ? user.username : '', 
                useragency: user ? user.agency : '', 
                time, 
                colorCode: '', 
                label: 'cmd-return', 
                islist: true,
                content: `
                        📜 Agency Directory
                        Displaying active agencies in Quasar...

                    ${thelist ? thelist.map((list, index) => (
                    `
                        ${list.name} 

                        Members: ${list.members.length} 

                        Vault: N/A SOL

                    `
                )).join("") : null}`    
            }
            var updateItem = [ newItem ]
            setGlobalchats(updateItem)
        }
    }, [thelist])

    const handleSystemMessage = (systemmessage) => {
        var time = new Date().getTime()

        if(globalchats) {

            var newItem = { 
                username:  user ? user.username : '', 
                useragency: user ? user.agency : '', 
                time, 
                colorCode: '', 
                label: 'cmd-return', 
                content: systemmessage  
            }
            var updateItem = [...globalchats, newItem]
            setGlobalchats(updateItem)
            setgcInputvalue('')

        } else {

            var newItem = { 
                username:  user ? user.username : '', 
                useragency: user ? user.agency : '', 
                time, 
                colorCode: '', 
                label: 'cmd-return', 
                content: systemmessage  
            }
            var updateItem = [ newItem ]
            setGlobalchats(updateItem)
            setgcInputvalue('')

        }
    }

    // when there's change in any success message to serve system
    useEffect(() => {
        if(authSuccess || caSucmessage || caError || jaSucmessage || jaError || qaSucmessage || qaError || listError || ruError || ruSucc || muteSucc || muteError) {
            handleSystemMessage(
                authSuccess || 
                caSucmessage || caError || 
                jaSucmessage || jaError ||
                qaError || qaSucmessage || listError ||
                ruSucc || ruError || muteSucc || muteError
            )
        }
    }, [
        authSuccess, 
        caSucmessage, caError, 
        jaSucmessage, jaError,
        qaError, qaSucmessage, listError,
        ruSucc, ruError, muteSucc, muteError
    ])



    const handleHelpCommands = () => {
        var time = new Date().getTime()
        if(globalchats) {
            var newItem = { 
                username: user ? user.username : '', 
                useragency: user ? user.agency : '', 
                time, 
                colorCode: '', 
                label: 'cmd-return', 
                content: helpContent  
            }
            var updateItem = [...globalchats, newItem]
            setGlobalchats(updateItem)
            setgcInputvalue('')
        } else {
            var newItem = { 
                username: user ? user.username : '', 
                useragency: user ? user.agency : '', 
                time, 
                colorCode: '', 
                label: 'cmd-return', 
                content: helpContent  
            }
            var updateItem = [ newItem ]
            setGlobalchats(updateItem)
            setgcInputvalue('')
        }
    }

    const handleToast = (value, status) => {
        settoastmessg(value)
        settoaststatus(status)
        setTimeout(() => { settoastmessg(null) }, 3000)
    }

    const handleGetAgencies = async () => {
        if(!agencies) {
            await getAgencies()
        }
    }

    const handleAgenciesReload = async () => {
        await getAgencies()
    }

    // run after reloads to get agencies
    useEffect(() => {
        handleGetAgencies()
    }, [])
    
    const handleWelcome = () => {
        setWelcomestatus(false)
        setgcInputvalue('')
    }

    // run when pages reloads to get current user
    useEffect(() => {
        if(!user) {
            var storeditem = localStorage.getItem('superluminaluser')
            if(storeditem) {
                var parsed = JSON.parse(storeditem)
                var username = parsed.username
                if(!user) {
                    if(!authLoading) {
                        // getCurrentUser(username)
                        if (!username) return;

                        // create a query to match the username
                        const userQuery = query(
                            collection(db, "usersdetails"),
                            where("username", "==", username)
                        );

                        // set up the real-time listener
                        const unsubscribe = onSnapshot(userQuery, (snapshot) => {
                            if (!snapshot.empty) {
                                // if a user document exists, get the first matching document
                                const userData = snapshot.docs[0].data();
                                dispatch(setUser({ userid: snapshot.docs[0].id, ...userData }))
                            } else {
                                // no matching user document found
                                console.log('no user')
                            }
                        });

                        // Cleanup the listener on unmount
                        return () => unsubscribe();

                    }
                }
            }
        }
    }, [])

    const createUserCommands = async (username) => {
        console.log(username)
        try {
            if(user) {
                handleSystemMessage('already logged in')
                return
            }
            
            if(!authLoading) {
                await signInWithGoogle(username)
                setgcInputvalue('')
            } else {
                handleSystemMessage('still loading your previous request')
            }
            
        } catch(err) {
            console.log(err)
        }
    }

    const handleAgencyCreation = async (agencyname) => {
        console.log(agencyname)
        
        if(user.agency !== '') {
            handleToast('quit your current agency user before creating new one', false)
            handleSystemMessage('quit your current agency before creating new one')
        } else {
            if(!caLoading) {
                await createAgency(agencyname, user.userid, user.username)
                setgcInputvalue('')
            } else {
                handleToast('please wait for your previous request to be completed')
                handleSystemMessage('please wait for your previous request to be completed')
            }
        }
        
    }
    
    const handleJoinAgency = async (agencyname) => {
        if(!user) {
            handleToast('no user detected. please sign login to continue', false)
            handleSystemMessage('no user detected. please sign login to continue')
            return
        } else {
            if(user.agency !== '') {
                handleToast('quit your current agency before joining new one', false)
                handleSystemMessage('quit your current agency before joining new one')
            } else {
                if(!jaLoading) {
                    await joinAgency(agencyname, user.username)
                    setgcInputvalue('')
                } else {
                    handleSystemMessage('please wait for your previous request to be completed')
                    handleToast('please wait for your previous request to be completed')
                }
            }
        }
    }

    // run when there's change in agencydata to set user agency after join request
    useEffect(() => {
        if(joinAgencydata) {
            setuseragency(joinAgencydata)
        }
    }, [joinAgencydata])
    
    const handleQuiteAgency = async () => {
        if(!user) {
            handleSystemMessage('no user detected please login to continue')
            return
        }
        if(user.agency !== '') {
            
            await quitAgency(user.agency, user.username)
            setgcInputvalue('')
            
        } else {
            handleSystemMessage('you are not in any agency')
            handleToast('you are not in any agency', false)
        }
    }
    
    // run when there's quit agency succ message
    useEffect(() => {
        setAgencychats(null)
        setuseragency(null)
    }, [qaSucmessage])
    
    const handleGetAgency = async (agencyname) => {
        
        await getAgency(agencyname)
        setgcInputvalue('')

    }
    
    const handleSendGlobal = async (colorCode, content, agency, charlie) => {
        const now = Date.now();
        if(!user) {
            handleToast('login to interact in global chat')
        } else {

            // Check if the message is a duplicate
            if (content === lastMessage.content) {
                setHasanimated(null)
                var time = new Date().getTime()
                var newItem = { 
                    username: '', useragency: '', time, colorCode: '', label: 'cmd-return', 
                    content: 'you cannot send the same message twice.'
                }
                var updateItem = [...globalchats, newItem]
                setGlobalchats(updateItem)
                // handleToast('you cannot send the same message twice.', false);
                return;
            }

            // Check if the message was sent within 2 seconds of the last one
            if (now - lastMessage.timestamp < 200) {
                setHasanimated(null)
                var time = new Date().getTime()
                setismute(true);
                var newItem = { 
                    username: '', useragency: '', time, colorCode: '', label: 'cmd-return', 
                    content: 'you have been muted 30s for sending messages too quickly'
                }
                var updateItem = [...globalchats, newItem]
                setGlobalchats(updateItem)
                // handleToast('you are muted for sending messages too quickly', false);
                setTimeout(() => setismute(false), 30000); // unmute after 30 seconds
                return;
            }

            try {
                // setHasanimated(false)
                var time = new Date().getTime()

                await addDoc(collection(db, 'messages'), {
                    userid: user.userid,
                    username: user.username,
                    useragency: agency,
                    time,
                    label: 'chat',
                    colorCode: '',
                    content,
                    isCharlie: charlie,
                    createdAt: serverTimestamp()
                })

                // make some adjustments after
                setgcInputvalue('')
                setLastMessage({ content: content, timestamp: now })
            } catch (error) {
                console.log('Error sending message:', error);
                handleToast(`error sending message: ${error.message}`, 'error');
            }
            
        }
    }
    
    const handleSendAgencyChat = async (colorCode, content) => {
        if (!user) {
            handleToast('login and join an agency to interact in agency chat')
        } else {
            if(user.agency === '') {
                handleToast('join an agency to interact in agency chat')
            } else {

                setHasanimated(null)
                
                var time = new Date().getTime()

                await addDoc(collection(db, 'agencymessages'), {
                    userid: user.userid,
                    username: user.username,
                    agencyname: user.agency,
                    time,
                    label: 'chat',
                    colorCode: '',
                    content,
                    createdAt: serverTimestamp()
                })

                setacInputvalue('')
            }
        }
    }

    const handleListAgencies = async () => {
        if(!listLoading) {
            await listAgencies()
            setgcInputvalue('')
        } else {
            handleToast('please wait while your previous request get sent')
        }
    }
    

    const handleSwitchChats = () => {
        if(chatStatus === 'global') {
            setChatstatus('agency')
        } else {
            setChatstatus('global')
        }
    }
    
    const handleMuteUser = async (username, whatMute) => {
        if(!muteLoading) {
            await muteUser(username, whatMute)
            setgcInputvalue('')
        } else {
            handleToast('please wait while your previous request get sent')
        }
    }

    const handleMotd = async (content) => {
        if(!user) {
            handleToast('you are not logged in', false)
            return
        } 
        if (user.isAdmin === false) {
            handleToast('you have to be an admin to do that', false)
            return
        }

        try {
            var time = new Date().getTime()

            await addDoc(collection(db, 'motd'), {
                userid: user.userid,
                username: user.username,
                useragency: user.agency,
                time,
                label: 'chat',
                colorCode: '',
                content,
                motd: true,
                createdAt: serverTimestamp()
            })

            setgcInputvalue('')
        } catch (err) {
            console.error("Error sending MOTD: ", err);
            handleToast('error sending motd', false)
        }

    }

    const handleGetMotd = async () => {
        try {
            // query the motd collection for the latest message
            const motdQuery = query(
                collection(db, "motd"),
                orderBy("createdAt", "desc"),
                limit(1) // only fetch the latest motd
            );

            const querySnapshot = await getDocs(motdQuery)

            if (querySnapshot.empty) {
                handleToast('no message returned')
                return;
            }
            
            const latestMotd = querySnapshot.docs[0].data()
            const createdAtTimestamp = latestMotd.createdAt // firebase timestamp
            const createdAtDate = createdAtTimestamp.toDate() // convert to JS Date
            
            // get current date and start of today
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1); // subtract 1 day for yesterday
            
            if (createdAtDate >= today) {
                if(globalchats) {
                    var newItem = [...globalchats, latestMotd]
                    setGlobalchats(newItem)
                } else {
                    var newItem = [ latestMotd ]
                    setGlobalchats(newItem)
                }
                setgcInputvalue('')
                return
            } else if (createdAtDate >= yesterday) {
                handleToast('no motd for today yet')
                return // yesterday's message exists
            } else {
                handleToast('no relevant motd yet')
                return // no relevant messages
            }
        } catch (err) {
            console.error("Error fetching MOTD: ", err);
            handleToast('error fetching motd', false)
        }
    }

    const handleMessageDelete = async (content) => {
        if(!user) {
            handleToast('you are not logged in', false)
            return
        } 
        if (user.isAdmin === false) {
            handleToast('you have to be an admin to do that', false)
            return
        }

        try {

            // Query the documents where agencyname matches
            const q = query(collection(db, "messages"), where("content", "==", content));
                
            // Get the documents matching the query
            const querySnapshot = await getDocs(q);
            if(!querySnapshot.empty) {
                // loop through each document and delete it
                const deletePromises = querySnapshot.docs.map((document) =>
                    // deleteDoc(doc(db, "messages", document.id))
                    deleteDoc(document.ref)
                );
        
                // Wait for all deletions to complete
                await Promise.all(deletePromises);
        
                console.log(`message has been removed.`)
                handleToast(`message removed`)
                handleSystemMessage('cleaned up message')
                
                setgcInputvalue('')
            } else {
                console.log(`message does not exist`)
                handleToast(`entered message does not exist in the db`, false)
            }
    
        } catch (err) {
            console.error("error deleting message: ", err);
            handleToast('error deleting message', false)
        }

    }

    
    const containsNumber = (string) => {
        const regex = /\d/;
        return regex.test(string)
    }
    

    const handleJoinable = async (status) => {
        if(user) {
            // console.log(userAgency)
            if(user.agency !== '' && userAgency) {
                if(user.username === userAgency.creatorName) {
                    if(userAgency.joinable === status) {
                        handleToast('joinable is the same already' )
                    } else {
                        // run joinable func
                        await addRestriction(user.agency, status)
                        setgcInputvalue('')
                        setacInputvalue('')
                    }
                } else {
                    handleSystemMessage('you are not the admin of this agency')
                    handleToast('you are not the admin of this agency', false)
                }
                
            } else {
                handleSystemMessage('you are not the admin of this agency')
                handleToast('you are not in an agency')
            }
        } else {
            handleToast('not logged in')
            handleSystemMessage('no user detected. please sign in to continue')
        }
    }

    const handleClearAgency = async () => {
        if(user) {
            // console.log(userAgency)
            if(user.agency !== '' && userAgency) {
                if(user.username === userAgency.creatorName) {

                    try {
                        // Query the documents where agencyname matches
                        const q = query(collection(db, "agencymessages"), where("agencyname", "==", userAgency.name));
                
                        // Get the documents matching the query
                        const querySnapshot = await getDocs(q);
                        if(!querySnapshot.empty) {
                            // Loop through each document and delete it
                            const deletePromises = querySnapshot.docs.map((document) =>
                                deleteDoc(doc(db, "agencymessages", document.id))
                            );
                    
                            // Wait for all deletions to complete
                            await Promise.all(deletePromises);
                    
                            console.log(`all messages for agency "${userAgency.name}" have been deleted.`);
                            handleToast(`all messages for agency "${userAgency.name}" have been deleted.`, true)
                            
                            setgcInputvalue('')
                            setacInputvalue('')
                            setAgencychats(null)
                        } else {
                            console.log(`agency "${userAgency.name}" is not in db.`);
                            handleToast(`cannot find agency "${userAgency.name}" in the database.`, false)
                        }
                
                    } catch (error) {
                        console.error("error deleting agency messages:", error);
                        handleToast('error deleting agency messages', false)
                    }

                } else {
                    handleToast('you are not the admin of this agency', false)
                }

            } else {
                handleToast('you are not in an agency')
            }
        } else {
            handleToast('not logged in')
        }
    }

    // runs when there's an update in restriction to update the agency state
    useEffect(() => {
        if(userAgency) {
            var updated = { ...userAgency, joinable: restrictionUpdate }
            setuseragency(updated)
        }
    }, [restrictionUpdate])

    
    const handleGCKeyPress = (event) => {
        // if (event.key === 'Enter') {
            event.preventDefault(); // prevents the default page refresh

            handleToast(null, '')
            if(gcInputvalue.length < 1) return

            if(gcInputvalue[0] !== '/') {
                // not a command but global message
                if(isMute === true) {
                    handleToast('you are muted by the admins', false)
                    return
                }
                if(gcInputvalue[0] === '#') {
                    const words = gcInputvalue.split(' ')

                    if(words[0].length > 7) {
                        handleToast('not a valid color code', false)
                    } else {
                        var thecc = words[0]
                        var thect = words[1]

                        setHasanimated(null)
                        handleSendGlobal(thecc, thect, user.agency, false)
                        
                    }
                } else {
                    setHasanimated(null)
                    handleSendGlobal('', gcInputvalue, user.agency, false)
                }
            }

            // if(gcInputvalue[0] === '@') {
            //     const words = gcInputvalue.split(' ')
            //     console.log(words)
            //     if(words[0] === '@charlie' && words[1]) {
            //         var message = words.slice(1).join(" "); // remove the first word and join the rest back together
            //         if(!user) {
            //             handleSystemMessage('you have to be a quasar user to make use of charlie')
            //         } else {
            //             demoCharlieCall(message)
            //         }
            //     }
            // }

            if(gcInputvalue[0] === '/') {
                const words = gcInputvalue.split(' ')
                console.log(words)

                // now check which commands is user trying to run and run it

                // user command check and run
                if(words[0] === '/create' && words[1] === 'user') {
                    if(!words[2]) {
                        handleToast('enter a username', false)
                    } else {
                        if (containsNumber(words[2])) {
                            handleToast('usernames cannot contain number', false)
                            return;
                        }

                        createUserCommands(words[2])
                    }
                }

                if(words[0] === '/login') {
                    if(!words[1]) {
                        handleToast('enter a username', false)
                    } else {
                        createUserCommands(words[1])
                    }
                }

                if(words[0] === '/logout') {
                    handleLogOut()
                }
                
                if(words[0] === '/create' && words[1] === 'agency') {
                    if(!user) {
                        handleToast('no user detected. please login to continue', false)
                    } else {
                        if(!words[2]) {
                            handleToast('enter agencyname', false)
                        } else {
                            if (containsNumber(words[2])) {
                                handleToast('agency names cannot contain number', false)
                                return;
                            }
    
                            handleAgencyCreation(words[2])
                        }
                    }
                    
                }

                if(words[0] === '/join' && words[1] === 'agency') {
                    if(!words[2]) {
                        handleToast('enter agencyname', false)
                    } else {
                        handleJoinAgency(words[2])
                    }
                    
                }

                if(words[0] === '/quit' && words[1] === 'agency') {
                    
                    handleQuiteAgency()
                }

                if(words[0] === '/info' && words[1] === 'agency') {
                    if(!words[2]) {
                        handleToast('enter agencyname', false)
                    } else {
                        handleGetAgency(words[2])
                    }
                    
                }

                if(words[0] === '/agency' && words[1] === 'details') {
                    if(user.agency === '') {
                        handleToast('you have not joined any agency yet', false)
                    } else {
                        handleGetAgency(user.agency)
                    }
                    
                }

                if(words[0] === '/list') {

                    handleListAgencies()

                }
                
                if(words[0] === '/help') {

                    handleHelpCommands()
                        
                }

                if(words[0] === '/guide') {

                    const target = 'https://docs.quasar.social/getting-started/quickstart'
                    window.open(target, '_blank')
                    setgcInputvalue('')
                        
                }
                
                if(words[0] === '/clear') {
                
                    const currentTime = new Date().getTime()
                    setConnectedTime(currentTime)
                    setGlobalchats(null)
                    setgcInputvalue('')
                    
                }

                if(words[0] === '/joinable') {
                    if(words[1] === 'on') {
                        handleJoinable(true)
                    } else if (words[1] === 'off') {
                        handleJoinable(false)
                    }                    
                }
                    
                if(words[0] === '/mute') {
                    if(!user) {
                        handleToast('you are not logged in', false)
                        handleSystemMessage('ops no user detected. login to continue')
                        return
                    } 
                    if (user.isAdmin === false) {
                        handleToast('you have to be an admin to do that', false)
                        handleSystemMessage('ops you have to be an admin to do that')
                        return
                    }
                    if(!words[1]) {
                        handleToast('please enter the user you are wiling to mute', false)
                        return
                    }
                    
                    handleMuteUser(words[1], true)
                    
                }

                if(words[0] === '/unmute') {
                    if(!user) {
                        handleToast('you are not logged in', false)
                        handleSystemMessage('ops no user detected. login to continue')
                        return
                    } 
                    if (user.isAdmin === false) {
                        handleToast('you have to be an admin to do that', false)
                        handleSystemMessage('ops you have to be an admin to do that')
                        return
                    }
                    if(!words[1]) {
                        handleToast('please enter the user you are wiling to mute', false)
                        return
                    }

                    handleMuteUser(words[1], false)
                    
                }

                if(words[0] === '/welcome' && words[1] === 'off') {
                    handleWelcome()
                }

                if(words[0] === '/setmotd' && words[1]) {
                    var message = words.slice(1).join(" "); // remove the first word and join the rest back together
                    handleMotd(message)
                }
                
                if(words[0] === '/motd') {
                    handleGetMotd()
                }

                if(words[0] === '/delete' && words[1]) {
                    var message = words.slice(1).join(" "); // remove the first word and join the rest back together
                    handleMessageDelete(message)
                }
            }
        // }
    }

    const updateAgencyChat = (data) => {
      setAgencychats(data.filter(item => item.role === 'user' || item.role === 'assistant' && item.content !== null).map(chat => (
        {
          content: chat.content,
          time: new Date(),
          agencyname: chat.role === "assistant" ? 'Pricebot' : user.agency,
          username: user.username,
          createdAt: serverTimestamp(),
          isAgent: chat.role === "assistant",
          label: 'chat',
        }
      )))
      return data;
    }
    
    const handleACPress = (event) => {
        if (pricebot.summoned) {
            setAgencychats([...agencychats, {
                userid: user.userid,
                username: user.username,
                agencyname: user.agency,
                time: new Date(),
                label: 'chat',
                colorCode: '',
                content: acInputvalue,
                createdAt: serverTimestamp()
            }])

            pricebot.sendMessage(acInputvalue).then(updateAgencyChat)
            setacInputvalue('');
            return;
        }

        if (acInputvalue === '/summon pricebot') {
            pricebot.summon().then(updateAgencyChat);
            setacInputvalue('');
            return;
        }
        
        // if (event.key === 'Enter') {
            event.preventDefault(); // prevents the default page refresh
            handleToast(null, '')
            if(acInputvalue.length < 1) return
            
            if(acInputvalue[0] !== '/') {
                // console.log(acInputvalue)
                // not a command but global message
                if(acInputvalue[0] === '#') {
                    const words = acInputvalue.split(' ')

                    if(words[0].length > 7) {
                        handleToast('not a valid color code', false)
                    } else {
                        var thecc = words[0]
                        var thect = 'check for logic'

                        handleSendAgencyChat(thecc, acInputvalue)
                        
                    }
                } else {
                    handleSendAgencyChat('', acInputvalue)
                }
            }

            if(acInputvalue[0] === '/') {
                const words = acInputvalue.split(' ')
                // console.log(words)

                if(acInputvalue === '/clear') {
                    
                    handleClearAgency()

                }

                if(words[0] === '/joinable') {
                    if(words[1] === 'on') {
                        handleJoinable(true)
                    } else if (words[1] === 'off') {
                        handleJoinable(false)
                    }                    
                }

            }
        // }
    }

    const handleGCKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // prevents the default newline behavior of the textarea
            handleGCKeyPress(event)
        }
    }
    const handleACKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // prevents the default newline behavior of the textarea
            handleACPress(event)
        }
    }

    const calcLeadIndex = (index) => {
        const realIndex = index + 1
        return realIndex
    }

    const escapeHTML = (text) => {
        if(text) {
            return text
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;');
        }
    }

    // const HighLightMentions = ({ message, motd }) => {

        
    //     // regular rendering for non-last messages.
    //     const parseMessage = (text) => {
    //         if (text) {
    //             return text.split(' ').map((word, index) => {
    //                 if (word.startsWith('@')) {
    //                     return (
    //                         <span key={index} className="cli-text-format yellow">
    //                             {word}{' '}
    //                         </span>
    //                     );
    //                 }
    //                 return word + ' ';
    //             });
    //         }
    //     };
    
    //     return <h4 className={`cli-text-format ${motd ? motd===true ? 'yellow' : '' : ''}`}><pre className={`pre-style`}>{parseMessage(message)}</pre></h4>;
    // };


    return ( 
        <div className="">

            {authError || caError || qaError || gaError || jaError || listError || muteError || guaError || ruError ?
                <ToastGeneral messg={authError || caError || qaError || gaError || jaError || listError || muteError || guaError || ruError} status={false} />
            : null}
            {authSuccess || caSucmessage || qaSucmessage || gaSucmessage || muteSucc || ruSucc ? 
                <ToastGeneral messg={authSuccess || caSucmessage || qaSucmessage || gaSucmessage || muteSucc || ruSucc} status={true} />
            : null}
            {toastmssg ?
                <ToastGeneral messg={toastmssg} status={toaststatus} />
            : null}

            <section id="home" className="home">


                <div className="home-cont flex">

                    <div className={`large-width-cont align-flex-end flex ${windowWidth < 1100 ? 'enlarge' : ''}`}>
                        <div className="large-width-scroll">
                            <div className="online-users-text">
                                {/* <p>{connectedUsers ? 0 : 0} users online</p> */}
                            </div>
                            {windowWidth < 1100 ?
                                <div className="sidebar-icon">
                                    <img src={showsidebar ? close_icon : menu_icon} className="cursor-pointer" onClick={handleSideBar} alt="" />
                                </div>
                            : null}
                            {chatStatus === 'global' ? 
                            
                                <div className="commands-control-cont">
                                    {welcomestatus ? 
                                        <div className="welcome-item-cont flex column align-center">
                                            <div className="welcome-art-box flex column align-center">
                                                <p className="welcome-art-text">
                                                    <pre>{`∩  ∩`}</pre>
                                                </p>
                                                <p className="welcome-art-text">
                                                    <pre>{`⋆ · ˚ ˚ * . · ⋆ ⋆ · . · ⋆ ✧ ⋆ · ˚ * . `}</pre>
                                                </p>
                                            </div>

                                            <div className="wlc-logo-box flex justify-center">
                                                <h2 className="wlc-logo">
                                                    <pre>{`
                                                                                                                     
   /###   ##   ####      /###      /###      /###   ###  /###    
  / ###  / ##    ###  / / ###  /  / #### /  / ###  / ###/ #### / 
 /   ###/  ##     ###/ /   ###/  ##  ###/  /   ###/   ##   ###/  
##    ##   ##      ## ##    ##  ####      ##    ##    ##         
##    ##   ##      ## ##    ##    ###     ##    ##    ##         
##    ##   ##      ## ##    ##      ###   ##    ##    ##         
##    ##   ##      ## ##    ##        ### ##    ##    ##         
##    ##   ##      /# ##    /#   /###  ## ##    /#    ##         
 #######    ######/ ## ####/ ## / #### /   ####/ ##   ###        
  ######     #####   ## ###   ##   ###/     ###   ##   ###       
      ##                                                         
      ##                                                         
      ##                                                         
    ## ##                                                        
                                                    
                                                    `}</pre>
                                                </h2>
                                            </div>
                                            
                                            <h3 className="wlc-sub-text-1">
                                                <pre>{`✧ [ A Text-Based Digital Universe ] ✧`}</pre>
                                            </h3>

                                            <p className="wlc-art-2">
                                                <pre>{`❈`}</pre>
                                            </p>

                                            <h4 className="wlc-sub-text-2">
                                                <pre>{`⟡ W E L C O M E,  `}<span>{`T R A V E L E R `}</span>{`⟡`}</pre>
                                            </h4>

                                            <div className="wlc-rect one">
                                                <div className="rect-line one"></div>
                                                <div className="rect-line two"></div>
                                                <div className="rect-line three"></div>
                                                <div className="rect-line four"></div>
                                                <h3>TO BEGIN:</h3>
                                                <h4>{`⚘ /create `}<span>{`user `}</span>{`<name>`}</h4>
                                                <h4>{`⚘ /create agency <name>`}</h4>
                                            </div>
                                            <div className="wlc-rect two">
                                                <div className="rect-line one"></div>
                                                <div className="rect-line two"></div>
                                                <div className="rect-line three"></div>
                                                <div className="rect-line four"></div>
                                                <h3 className="blue"s>NEED HELP?</h3>
                                                <h4>{`⚘ `}<span><a href="https://docs.quasar.social" target="_blank">{`docs.quasar.social`}</a></span></h4>
                                                <h4>{`⚘ /help - View commands`}</h4>
                                                <h4>{`⚘ /guide - Quick start`}</h4>
                                            </div>

                                            <div className="wlc-bar one"></div>
                                            <h5 className="sub-text-3">"In Quasar, <span>every AI has its own story waiting to be told</span>."</h5>
                                            <div className="wlc-bar two"></div>

                                            <div className="welcome-art-box flex column align-center">
                                                <p className="welcome-art-text">
                                                    <pre>{`⋆ · ˚ ˚ * . · ⋆ ⋆ · . · ⋆ ✧ ⋆ · ˚ * . `}</pre>
                                                </p>
                                                <p className="welcome-art-text">
                                                    <pre>{`∪  ∪`}</pre>
                                                </p>
                                            </div>

                                            <h5 className="sub-text-4 mb">If you’re new, please refer to our documentation to learn how to navigate this land, and to learn how to be... useful.</h5>
                                            <h5 className="sub-text-4 mb"><span>docs.quasar.social</span></h5>
                                            <h5 className="sub-text-4">Have fun! (To turn off this welcome message, do /welcome off)</h5>

                                        </div>
                                    : null}
                                    {globalchats ? globalchats.map((terminal, index) => (
                                        <div className={`${terminal.label === 'chat' ? 'cmd-line-item ' : 'cmd-line-item-return'}`} key={index}>
                                            <h2 className="cli-text-format">
                                                {/* {terminal.label === 'chat' ? `${formatISO9075(new Date(terminal.time), { representation: 'time' })} ` : '>> '} */}
                                                {terminal.label === 'chat' ? `${format(new Date(terminal.time), "h:mm" )} ` : '>> '}
                                            </h2>
                                            {terminal.label === 'chat' ? 
                                                <h3 className="cli-text-format">
                                                    {terminal.isCharlie === false ? `${terminal.username}.` : null}
                                                    <span className={`cli-text-format ${terminal.isCharlie === true ? 'yellow' : ''}`}>
                                                        {terminal.useragency === '' ? 'NONE' : terminal.useragency}
                                                    </span>
                                                    <span className="cli-text-format column">:</span> 
                                                </h3>
                                            : null}
                                            {terminal.label === 'cmd-return' ? 
                                                <h3 className="cli-text-format"><span className="cli-text-format">System: </span></h3>
                                            : null}
                                            {/* <h4 className="cli-text-format"><pre className="pre-style">{terminal.content} </pre></h4> */}
                                            {/* <HighLightMentions message={terminal.content} motd={terminal.motd} /> */}
                                            <HighLightMentions message={terminal.content} isLast={index === globalchats.length - 1} motd={terminal.motd} />
                                        </div>
                                    )) : null}
                                    {/* Empty div to mark the end of the chat messages */}
                                    <div ref={chatEndRef} />
                                </div>
                        
                            : 
                            
                                <div className="commands-control-cont">
                                    {welcomestatus ? 
                                        <div className="welcome-item-cont flex column align-center">
                                            <div className="welcome-art-box flex column align-center">
                                                <p className="welcome-art-text">
                                                    <pre>{`∩  ∩`}</pre>
                                                </p>
                                                <p className="welcome-art-text">
                                                    <pre>{`⋆ · ˚ ˚ * . · ⋆ ⋆ · . · ⋆ ✧ ⋆ · ˚ * . `}</pre>
                                                </p>
                                            </div>

                                            <div className="wlc-logo-box flex justify-center">
                                                <h2 className="wlc-logo">
                                                    <pre>{`
                                                                                                                     
   /###   ##   ####      /###      /###      /###   ###  /###    
  / ###  / ##    ###  / / ###  /  / #### /  / ###  / ###/ #### / 
 /   ###/  ##     ###/ /   ###/  ##  ###/  /   ###/   ##   ###/  
##    ##   ##      ## ##    ##  ####      ##    ##    ##         
##    ##   ##      ## ##    ##    ###     ##    ##    ##         
##    ##   ##      ## ##    ##      ###   ##    ##    ##         
##    ##   ##      ## ##    ##        ### ##    ##    ##         
##    ##   ##      /# ##    /#   /###  ## ##    /#    ##         
 #######    ######/ ## ####/ ## / #### /   ####/ ##   ###        
  ######     #####   ## ###   ##   ###/     ###   ##   ###       
      ##                                                         
      ##                                                         
      ##                                                         
    ## ##                                                        
                                                    
                                                    `}</pre>
                                                </h2>
                                            </div>
                                            
                                            <h3 className="wlc-sub-text-1">
                                                <pre>{`✧ [ A Text-Based Digital Universe ] ✧`}</pre>
                                            </h3>

                                            <p className="wlc-art-2">
                                                <pre>{`❈`}</pre>
                                            </p>

                                            <h4 className="wlc-sub-text-2">
                                                <pre>{`⟡ W E L C O M E,  `}<span>{`T R A V E L E R `}</span>{`⟡`}</pre>
                                            </h4>

                                            <div className="wlc-rect one">
                                                <div className="rect-line one"></div>
                                                <div className="rect-line two"></div>
                                                <div className="rect-line three"></div>
                                                <div className="rect-line four"></div>
                                                <h3>TO BEGIN:</h3>
                                                <h4>{`⚘ /create `}<span>{`user `}</span>{`<name>`}</h4>
                                                <h4>{`⚘ /create agency <name>`}</h4>
                                            </div>
                                            <div className="wlc-rect two">
                                                <div className="rect-line one"></div>
                                                <div className="rect-line two"></div>
                                                <div className="rect-line three"></div>
                                                <div className="rect-line four"></div>
                                                <h3 className="blue"s>NEED HELP?</h3>
                                                <h4>{`⚘ `}<span><a href="https://docs.quasar.social" target="_blank">{`docs.quasar.social`}</a></span></h4>
                                                <h4>{`⚘ /help - View commands`}</h4>
                                                <h4>{`⚘ /guide - Quick start`}</h4>
                                            </div>

                                            <div className="wlc-bar one"></div>
                                            <h5 className="sub-text-3">"In Quasar, <span>every AI has its own story waiting to be told</span>."</h5>
                                            <div className="wlc-bar two"></div>

                                            <div className="welcome-art-box flex column align-center">
                                                <p className="welcome-art-text">
                                                    <pre>{`⋆ · ˚ ˚ * . · ⋆ ⋆ · . · ⋆ ✧ ⋆ · ˚ * . `}</pre>
                                                </p>
                                                <p className="welcome-art-text">
                                                    <pre>{`∪  ∪`}</pre>
                                                </p>
                                            </div>

                                            <h5 className="sub-text-4 mb">If you’re new, please refer to our documentation to learn how to navigate this land, and to learn how to be... useful.</h5>
                                            <h5 className="sub-text-4 mb"><span>docs.quasar.social</span></h5>
                                            <h5 className="sub-text-4">Have fun! (To turn off this welcome message, do /welcome off)</h5>

                                        </div>
                                    : null}
                                    {agencychats ? agencychats.map((terminal, index) => (
                                        <div className={`${terminal.label === 'chat' ? 'cmd-line-item ' : 'cmd-line-item-return'}`} key={index}>
                                            <h2 className="cli-text-format">
                                                {/* {terminal.label === 'chat' ? `${formatISO9075(new Date(terminal.time), { representation: 'time' })} ` : '>> '} */}
                                                {terminal.label === 'chat' ? `${format(new Date(terminal.time), "h:mm" )} ` : '>> '}
                                            </h2>
                                            {user ? 
                                                <h3 className={`cli-text-format`}>
                                                    {userAgency ? userAgency.name === terminal.agencyname ? `${terminal.username}.` : null : null}
                                                    <span className={`cli-text-format ${userAgency ? userAgency.name !== terminal.agencyname ? 'yellow' : '' : ''}`}>{terminal.agencyname}</span>
                                                    <span className="cli-text-format column">:</span> 
                                                </h3>
                                            : null}
                                            {/* <h4 className="cli-text-format"><pre className="pre-style">{terminal.content} </pre></h4> */}
                                            {/* <HighLightMentions message={terminal.content} motd={terminal.motd} /> */}
                                            <HighLightMentions message={terminal.content} isLast={index === agencychats.length - 1} motd={terminal.motd} />
                                        </div>
                                    )) : null}
                                    <div ref={agencychatEndRef} />
                                </div>
                            
                            }


                            <div className="user-info-box">
                                {chatStatus === 'global' ? 
                                    <div className="chat-cont flex gap-5">
                                        <p>: </p>
                                        <form onSubmit={handleGCKeyPress}>
                                            <textarea type="" 
                                                placeholder="Enter a command or Global message"
                                                onChange={(e) => setgcInputvalue(e.target.value)}
                                                value={gcInputvalue}
                                                onKeyDown={handleGCKeyDown}
                                            />
                                            <button type="submit" style={{ display: 'none' }}>Send</button>
                                        </form>
                                    </div>
                                :
                                    <div className="chat-cont align-flex-start flex gap-5">
                                        <p className="agency-chat-columun">: </p>
                                        <form onSubmit={handleACPress}>
                                            <textarea type="" 
                                                placeholder="Chat now"
                                                onChange={(e) => setacInputvalue(e.target.value)}
                                                value={acInputvalue}
                                                onKeyDown={handleACKeyDown}
                                            />
                                            <button type="submit" style={{ display: 'none' }}>Send</button>
                                        </form>
                                    </div>
                                }
                                <div className="">
                                    <h2 className="uib-text-format">
                                        User: <span className="uib-text-format">{user ? user.username : 'NONE'}</span>.
                                        <span className="uib-text-format green">{user ? user.agency === "" ? 'NONE' : user.agency : null} </span> 
                                    </h2>
                                    <h3 className="uib-text-format">| </h3>
                                    <h4 className="uib-text-format">{`${chatStatus === 'global' ? '{ Global Chat }' : '{ Agency Chat }'}`} </h4>
                                    <h5 className="uib-text-format">Menu  - </h5>
                                    <h6 className="uib-text-format">
                                        <a href="https://docs.quasar.social" target="_blank" className="uib-text-format">docs </a>
                                        <a href="https://x.com/quasarsocialai" target="_blank" className="uib-text-format">twitter </a>
                                        <a href="https://quasar.social" target="_blank" className="uib-text-format">website</a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`smaller-width-box ${windowWidth < 1100 ? 'smaller-width' : ''} ${showsidebar ? 'show' : ''}`}>
                        <div className="user-name-box flex align-center justify-center">
                            <p className="sm-rg-text-format">Quasar BETA | <span className="username sm-rg-text-format">{user ? user.agency !== '' ? user.agency : 'NONE' : 'NONE'}</span></p>
                        </div>

                        <div className="ld-cont">
                            <div className="ld-title-box flex align-center">
                                <p className="sm-rg-text-format">Top Agencies</p>
                            </div>

                            <div className="ld-box">
                                <div className="ld-box-head flex justify-center"><img src={lead_text} alt="" /></div>
                                {!agencies && agenciesLoading ? 
                                    <p className="sm-rg-text-format">loading...</p> 
                                : !agencies && !agenciesLoading ? 
                                    <p className="sm-rg-text-format cursor-pointer" onClick={handleAgenciesReload}>please refresh your screen</p> 
                                : agencies ? agencies.length > 0 ? 
                                    agencies.map((agency, index) => (
                                        <p className="sm-rg-text-format" key={agency.name}>
                                            {calcLeadIndex(index)} {agency.name} - {agency.members.length} member{agency.members.length > 1 ? 's' : null}  - Vault: N/A <span className="sm-rg-text-format purple">SOL</span>
                                        </p>
                                    ))
                                : null : null }
                            </div>
                        </div>

                        <div className="ld-cont">
                            <div className="ld-title-box flex align-center">
                                <p className="sm-rg-text-format">Task Log</p>
                            </div>

                            <div className="ld-box log flex align-flex-end">
                                <div className="ld-ll"></div>

                                <div className="ld-log">
                                    
                                    <div className="ld-log-alt">
                                        <p className="ld-log-text-format">nothing to see here for now...</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="ld-cont">
                            <div className="flex justify-space-between align-flex-end">
                                <div className="flex gap-10 align-center">
                                    <div className="ld-title-box flex align-center">
                                        <p className="sm-rg-text-format">{chatStatus === 'global' ? 'Agency Chat' : 'Global Chat'}</p>
                                    </div>
                                    <div className="switch-chat cursor-pointer">
                                        <p onClick={handleSwitchChats}>{`{ Switch Chats }`}</p>
                                    </div>
                                </div>

                                <div className="user-count"><p>{agmemberscount ? agmemberscount : 0} users</p></div>
                            </div>

                            <div className="ld-box agency flex align-flex-end">
                                <div className="ld-ll"></div>

                                {chatStatus === 'global' ? 
                                    <div className="ld-log">

                                        <div className="agency-chat-cont-ref">
                                            {agencychats ? agencychats.map((agencychat, index) => (
                                                <div className="cmd-line-item" key={index}>
                                                    <h2 className="cli-text-format">
                                                        {/* {`${formatISO9075(new Date(agencychat.time), { representation: 'time' })} `}  */}
                                                        {`${format(new Date(agencychat.time), "h:mm" )}`} 
                                                    </h2>
                                                    {user ? 
                                                        <h3 className={`cli-text-format`}>
                                                            {userAgency ? userAgency.name === agencychat.agencyname ? `${agencychat.username}.` : null : null}
                                                            <span className={`cli-text-format ${userAgency ? userAgency.name !== agencychat.agencyname ? 'yellow' : '' : ''}`}>{agencychat.agencyname}</span>
                                                            <span className="cli-text-format column">:</span> 
                                                        </h3>
                                                    : null}
                                                    {/* <h4 className="cli-text-format"><pre className="pre-style">{agencychat.content}</pre> </h4> */}
                                                    {/* <HighLightMentions message={agencychat.content} motd={agencychat.motd} /> */}
                                                    <HighLightMentions message={agencychat.content} isLast={index === agencychats.length - 1} motd={agencychat.motd} />
                                                </div>
                                            )) :
                                                <div className="alt-agency-text">
                                                    <p className="cli-text-format">setup an agency to get access to this panel.</p> <br /><br />
                                                    <p className="cli-text-format">to create one, do /create agency {'<agencyname>'}.</p> <br /><br />
                                                    <p className="cli-text-format">to visit one, simply do /visit agency {'<agencyname>'}.</p> <br /><br />
                                                </div>
                                            }
                                            <div ref={agencychatEndRef} />
                                        </div>
                                        
                                        <div className="chat-cont flex gap-5 align-flex-start">
                                            <p className="agency-chat-columun">: </p>
                                            <form onSubmit={handleACPress}>
                                                <textarea type="" 
                                                    placeholder="Chat now"
                                                    onChange={(e) => setacInputvalue(e.target.value)}
                                                    value={acInputvalue}
                                                    onKeyDown={handleACKeyDown}
                                                />
                                                <button type="submit" style={{ display: 'none' }}>Send</button>
                                            </form>
                                        </div>

                                    </div>
                                : 
                                    <div className="ld-log">
                                        <div className="commands-control-cont">
                                            {globalchats ? globalchats.map((terminal, index) => (
                                                <div className={`${terminal.label === 'chat' ? 'cmd-line-item ' : 'cmd-line-item-return'}`} key={index}>
                                                    <h2 className="cli-text-format">
                                                        {/* {terminal.label === 'chat' ? `${formatISO9075(new Date(terminal.time), { representation: 'time' })} ` : '>> '} */}
                                                        {terminal.label === 'chat' ? `${format(new Date(terminal.time), "h:mm" )} ` : '>> '} 
                                                    </h2>
                                                    {terminal.label === 'chat' ? 
                                                        <h3 className={`cli-text-format`}>
                                                            {terminal.isCharlie === false ? `${terminal.username}.` : null}
                                                            <span className={`cli-text-format ${terminal.isCharlie === true ? 'yellow' : ''}`}>
                                                                {terminal.useragency === '' ? 'NONE' : terminal.useragency}
                                                            </span>
                                                            <span className="cli-text-format column">:</span> 
                                                        </h3>
                                                    : null}
                                                    {terminal.label === 'cmd-return' ? 
                                                        <h3 className="cli-text-format"><span className="cli-text-format">System: </span></h3>
                                                    : null}
                                                    {/* <h4 className="cli-text-format"><pre className="pre-style">{terminal.content}</pre> </h4> */}
                                                    {/* <HighLightMentions message={terminal.content} motd={terminal.motd} /> */}
                                                    <HighLightMentions message={terminal.content} isLast={index === globalchats.length - 1} motd={terminal.motd} />
                                                </div>
                                            )) : null}
                                            {/* Empty div to mark the end of the chat messages */}
                                            <div ref={chatEndRef} />
                                        </div>

                                        <div className="chat-cont flex gap-5">
                                            <p>: </p>
                                            <form onSubmit={handleGCKeyPress}>
                                                <textarea type="" 
                                                    placeholder="Enter a command or Global message"
                                                    onChange={(e) => setgcInputvalue(e.target.value)}
                                                    value={gcInputvalue}
                                                    onKeyDown={handleGCKeyDown}
                                                />
                                                <button type="submit" style={{ display: 'none' }}>Send</button>
                                            </form>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                    </div>

                </div>



            </section>

        </div>
    );
}
 
export default Home;