import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDocs, collection, query, where, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from './../Firebase'

// states (redux)
import { setUser  } from "./../redux/action";


export const useJoinable = () => {
    const [loadingState, setLoadingState] = useState(false)
    const [error, setError] = useState(null)
    const [succmesg, setSucmsg] = useState(null)
    const [restrictionUpdate, setRestrictionUpdate] = useState(null)

        const addRestriction =  async (agencyname, status) => {
            setLoadingState(true)
            setError(null)
            try {

                // reference to the agencies collection
                const agenciesCollection = collection(db, "agencies");

                // query to find if a user exists with the given username
                const agencyQuery = query(agenciesCollection, where("name", "==", agencyname));

                // execute the query
                const querySnapshot = await getDocs(agencyQuery);

                // check if a document with the agencyname exists
                if (!querySnapshot.empty) {
                    // if document exists, update the status
                    const agencyDoc = querySnapshot.docs[0];
                    const agencyDocRef = doc(db, "agencies", agencyDoc.id);

                    const updateAgency = { joinable: status }

                    // update the document with the new data
                    await updateDoc(agencyDocRef, updateAgency);

                    setRestrictionUpdate(status)
                    setSucmsg(status === false ? 'restriction added' : 'restriction removed')
                    setTimeout(() => { setSucmsg(null) }, 3000)
                    setLoadingState(false)
                    
                } else {
                    // if agency does not exists, send a message that agency exist
                    setError(`agency with name "${agencyname}" does not exists. use /create agency ${agencyname} to create it`)
                    setTimeout(() => { setError(null) }, 3000)
                    console.log(`agency with name "${agencyname}" does not exists.`);
                    setLoadingState(false)
                }

            } catch (err) {
                console.log('try catch error: ', err.message)
                setError('an error occured while processing your request. please check your internet connection and try again')
                setTimeout(() => { setError(null) }, 3000)
                setLoadingState(false)
            }
        }


        return { addRestriction, loadingState, succmesg, error, restrictionUpdate } 
}