import React, { useEffect, useRef, memo } from "react";
import TextScramble from "../scripts/TextScramble"

const HighlightMentions = ({ message, isLast, motd }) => {
  // console.log(message,isLast)
  const ref = useRef();
  const hasAnimated = useRef(false);

  const escapeHTML = (text) => {
    if(text) {
        return text
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    }
  }

  const processMessage = (text) => {
    
    if (!text) return '';
    return text.split(' ').map((word, index) => {
      if (word.startsWith('@')) {
        return `<span class="mention-highlight cli-text-format yellow">${word}</span> `;
      }
      return word + ' ';
    }).join('');
  }

  const escaped = escapeHTML(message);
  const processedMessage = processMessage(escaped);

  useEffect(() => {
    if (isLast && ref.current && !hasAnimated.current) {
      const scrambleEffect = TextScramble(ref.current);
      scrambleEffect.setText(processedMessage).then(() => {
        hasAnimated.current = true;
      });
    }
  }, [message, isLast, processedMessage]);

  if (isLast) {
    return (
      <h4 className={`cli-text-format ${motd ? motd===true ? 'yellow' : '' : ''}`}>
        <pre className="pre-style" ref={ref}></pre>
      </h4>
    );
  }

  return (
    <h4 className={`cli-text-format ${motd ? motd===true ? 'yellow' : '' : ''}`}>
      <pre className="pre-style" dangerouslySetInnerHTML={{ __html: processedMessage }}></pre>
    </h4>
  );
};

export default memo(HighlightMentions);


