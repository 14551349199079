import { useState } from "react";
import { useDispatch } from "react-redux";
import { getDocs, collection, query, where, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from './../Firebase'

export const useAgenciesUsers = () => {
    const [error, setError] = useState(null)
    const [loadingState, setLoadingState] = useState(false)
    const [agusers, setUsers] = useState(null)
    const dispatch = useDispatch()

    const getAgenciesUsers = async (agencyname) => {
        try {

             // reference to the agencies collection
             const agenciesCollection = collection(db, "agencies");

             // query to find if a user exists with the given username
             const agencyQuery = query(agenciesCollection, where("name", "==", agencyname));

             // execute the query
             const querySnapshot = await getDocs(agencyQuery);

             // check if a document with the username exists
             if (!querySnapshot.empty) {
                 // if document exists, update the memebers array
                 const agencyDoc = querySnapshot.docs[0];
                 const agencyData = agencyDoc.data()

                var theUsers = agencyData.members.length
                setUsers(theUsers)

                 setLoadingState(false)
                 
             } else {

                 // if agency exists, send a message that agency exist
                 setError(`agency with name "${agencyname}" does not exists. use /create agency ${agencyname} to create it`)
                 setTimeout(() => { setError(null) }, 3000)
                 console.log(`agency with name "${agencyname}" does not exists.`);
                 setLoadingState(false)
             }
            
        } catch (err) {
            console.log('try catch error: ', err.message)
            setError('an error occured while processing agencies users. please check your internet connection and try again or refresh the page')
            setTimeout(() => { setError(null) }, 3000)
            setLoadingState(false)
        }
    }


    return { getAgenciesUsers, loadingState, error, agusers } 
}