// src/TextScramble.js

const TextScramble = (el) => {
    const chars = '█░▓▒▄█';
    let frame = 0;
    let queue = [];
    let resolveCallback;
  
    const randomChar = () => chars[Math.floor(Math.random() * chars.length)];
  
    const update = () => {
      let output = '';
      let complete = 0;
  
      queue.forEach((item) => {
        const { from, to, start, end } = item;
  
        if (frame >= end) {
          complete++;
          output += to;
        } else if (frame >= start) {
          if (!item.char || Math.random() < 0.28) {
            item.char = randomChar();
          }
          output += `<span class="scramble-text">${item.char}</span>`;
        } else {
          output += from;
        }
      });
  
      el.innerHTML = output;
  
      if (complete === queue.length) {
        resolveCallback();
      } else {
        frame++;
        requestAnimationFrame(update);
      }
    };
  
    const setText = (newText) => {
      const oldText = el.innerText || '';
      const length = Math.max(oldText.length, newText.length);
  
      queue = [];
      for (let i = 0; i < length; i++) {
        const from = oldText[i] || '';
        const to = newText[i] || '';
        const start = Math.floor(Math.random() * 20);
        const end = start + Math.floor(Math.random() * 30);
        queue.push({ from, to, start, end });
      }
  
      frame = 0;
  
      return new Promise((res) => {
        resolveCallback = res;
        update();
      });
    };
  
    return { setText };
  };
  
  export default TextScramble;
  