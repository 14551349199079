import { useState } from "react";
import { useDispatch } from "react-redux";
import { getDocs, collection, query, where, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from './../Firebase'

export const useGetUserAgency = () => {
    const [error, setError] = useState(null)
    const [loadingState, setLoadingState] = useState(false)
    const [tmpuseragency, setTmpuseragency] = useState(null)

    const getUserAgency = async (agencyname) => {
        try {

            // reference to the agencies collection
            const agenciesCollection = collection(db, "agencies");

            // query to find if a user exists with the given username
            const agencyQuery = query(agenciesCollection, where("name", "==", agencyname));

            // execute the query
            const querySnapshot = await getDocs(agencyQuery);

            // check if a document with the username exists
            if (!querySnapshot.empty) {
                // if document exists, update the memebers array
                const agencyDoc = querySnapshot.docs[0];
                const agencyData = agencyDoc.data()

                setTmpuseragency(agencyData)
                setLoadingState(false)
                
            } else {

                // if agency does not exists
                setError(`agency with name "${agencyname}" does not exists. use /create agency ${agencyname} to create it`)
                setTimeout(() => { setError(null) }, 3000)
                console.log(`agency with name "${agencyname}" does not exists.`);
                setLoadingState(false)
                
            }


        } catch (err) {
            console.log('try catch error: ', err.message)
            setError('An error occured while processing your request. Please check your internet connection and try again')
            setTimeout(() => { setError(null) }, 3000)
            setLoadingState(false)
        }
    }


    return { getUserAgency, loadingState, error, tmpuseragency } 
}