import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDocs, collection, query, where, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from './../Firebase'

// states (redux)
import { setUser  } from "./../redux/action";


export const useJoinAgency = () => {
    const { user } = useSelector(state=>state.appReducer);
    const dispatch = useDispatch()
    const [loadingState, setLoadingState] = useState(false)
    const [error, setError] = useState(null)
    const [succmesg, setSucmsg] = useState(null)
    const [joinAgencydata, setjoinagencydata] = useState(null)

        const joinAgency =  async (agencyname, username) => {
            setLoadingState(true)
            setError(null)
            try {

                var time = new Date().getTime()

                // reference to the agencies collection
                const agenciesCollection = collection(db, "agencies");

                // query to find if a user exists with the given username
                const agencyQuery = query(agenciesCollection, where("name", "==", agencyname));

                // execute the query
                const querySnapshot = await getDocs(agencyQuery);

                // check if a document with the username exists
                if (!querySnapshot.empty) {
                    // if document exists, update the memebers array
                    const agencyDoc = querySnapshot.docs[0];
                    const agencyData = agencyDoc.data()
                    if(agencyData.joinable === true) {
                        const agencyDocRef = doc(db, "agencies", agencyDoc.id);
    
                        const updateAgency = { members: [ ...agencyData.members, username ] }
    
                        // update the document with the new data
                        await updateDoc(agencyDocRef, updateAgency);
    
                        setjoinagencydata(agencyData)
    
                        
                        // query user docs to update
                        const userquery = query(collection(db, "usersdetails"), where("username", "==", username))
                        // execute the query
                        const userquerySnapshot = await getDocs(userquery);
    
                        if (!userquerySnapshot.empty) {
                            // get the first matching document (assuming usernames are unique)
                            const userDoc = userquerySnapshot.docs[0];
                            const userDocRef = doc(db, "usersdetails", userDoc.id);
    
                            const updatedData = { agency: agencyname, agDate: time }
                
                            // Update the document with the new data
                            await updateDoc(userDocRef, updatedData);
    
                            // update states
                            var itemUpdate = { ...user, agency: agencyname, agDate: time }
                            dispatch(setUser(itemUpdate))
                            setSucmsg(`agency "${agencyname}" has been joined.`)
                            setTimeout(() => { setSucmsg(null) }, 3000)
                            console.log(`agency "${agencyname}" has been joined.`);
                            console.log(`user with username "${username}" has been updated successfully.`);
                            setLoadingState(false)
                        } else {
                            setError(`no user found with username "${username}".`)
                            setTimeout(() => { setError(null) }, 3000)
                            console.log(`no user found with username "${username}".`);
                            setLoadingState(false)
                        }
                    } else {
                        setError(`agency with name "${agencyname}" cannot be joined`)
                        setTimeout(() => { setError(null) }, 3000)
                        console.log(`agency with name "${agencyname}" cannot be joined.`);
                        setLoadingState(false)
                    }
                    
                } else {

                    // if agency exists, send a message that agency exist
                    setError(`agency with name "${agencyname}" does not exists. use /create agency ${agencyname} to create it`)
                    setTimeout(() => { setError(null) }, 3000)
                    console.log(`agency with name "${agencyname}" does not exists.`);
                    setLoadingState(false)
                }

                
            } catch (err) {
                console.log('try catch error: ', err.message)
                setError('an error occured while processing your request. Please check your internet connection and try again')
                setTimeout(() => { setError(null) }, 3000)
                setLoadingState(false)
            }
        }


        return { joinAgency, loadingState, succmesg, error, joinAgencydata } 
}