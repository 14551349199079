import { useState } from "react";
import { useDispatch } from "react-redux";
import { getDocs, collection, query, where, addDoc, updateDoc, doc } from "firebase/firestore";
import { db } from './../Firebase'

// states (redux)
import { setAgencies  } from "./../redux/action";


export const useAgencies = () => {
    const [error, setError] = useState(null)
    const [loadingState, setLoadingState] = useState(false)
    const dispatch = useDispatch()

    const getAgencies = async () => {
        try {    


            // get a reference to the "agencies" collection
            const agenciesCollection = collection(db, "agencies");
            // fetch all documents in the collection
            const querySnapshot = await getDocs(agenciesCollection);
            // map the documents into an array of agency data
            const agenciesList = querySnapshot.docs.map((doc) => ({
                id: doc.id, // include the document ID
                ...doc.data(), // spread the document data
            }));
            // console.log(agenciesList)
            
            // const desiredNames = ['quasar', 'labs', 'Dune', 'GAS', 'WAGMI', '<BTC500K>'];
            const desiredNames = ['quasar', 'kamalagency', 'asar', 'hundredx', 'ABC', 'hello'];
            
            // filter the array
            const filteredArray = agenciesList.filter(item => desiredNames.includes(item.name));
            // console.log(filteredArray)

            dispatch(setAgencies(filteredArray))
            setLoadingState(false)
            
        } catch (err) {
            console.log('try catch error: ', err.message)
            setError('an error occured while fetching agencies. please check your internet connection and refresh the page')
            setTimeout(() => { setError(null) }, 3000)
            setLoadingState(false)
        }
    }


    return { getAgencies, loadingState, error } 
}